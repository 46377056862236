import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "flatpickr/dist/themes/material_blue.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  CardSubtitle,
  Button,
  Input,
  Toast,
  ToastHeader,
  ToastBody,
  UncontrolledAlert,
  Spinner,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { Result } from "antd";

function Article() {
  document.title = "Monday CRM | PrimeX";

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [toastError, setToastError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [toast, setToast] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [dasboardId, setDasboardId] = useState<any>();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [dashboard, setDashboard] = useState<any>("retention");



  interface CsvRow {
    ID?: string;
    Name?: string;
    Country?: string;
    LastLogin?: string;
    Phone?: string;
    Email?: string;
    FirstName?: string;
    LastName?: string;
    Language?: string;
  }

  const handleAcceptedFiles = () => {
    const file = selectedFiles[0];
    Papa.parse<CsvRow>(file, {
      header: true,
      complete: dashboard === "retention" ?
        (results: any) => {
          const formattedData = results.data.map((row: CsvRow) => ({
            id__1: row.ID,
            name: row.Name,
            text0__1: row.Country,
            text17__1: row.LastLogin,
            phone__1: row.Phone,
            email__1: row.Email,
          }));
          console.log(formattedData);
          // Object.assign(formattedData, {dasboardId: dasboardId})

          sendToBackend(formattedData, dasboardId);
        } : dashboard === "tele" ?
          (results: any) => {
            const formattedData = results.data.map((row: any) => (
              {
                name: row.Name,
                phone__1: String(`${row.Code}${row.Mobile}`),
                email__1: row.Email,
                text11__1: row.Lang,
                text57__1: row.Gender,
                text97__1: row.City
              }
            ));
            console.log(formattedData, "tele");
            // Object.assign(formattedData, {dasboardId: dasboardId})

            sendToBackend(formattedData, dasboardId);
          }
          :
          (results: any) => {
            const formattedData = results.data.map((row: CsvRow) => ({
              name: `${row.FirstName} ${row.LastName}`,
              phone__1: String(row.Phone),
              email__1: row.Email,
              text11__1: row.Language
            }));
            // console.log(formattedData);
            // Object.assign(formattedData, {dasboardId: dasboardId})

            sendToBackend(formattedData, dasboardId);
          },
      error: (error) => {
        setErrorMessage("Error parsing CSV file");
        setToastError(true);
      },
    });
  };

  console.log(dashboard, "data");


  const sendToBackend = async (record, dasboardId) => {
    setIsLoading(true);
    const data = {
      dasboardId: dasboardId,
      record,
    };
    try {
      const res = dashboard === "retention" ? await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/add/data/retention`,
        data
      ) :  
      dashboard === "tele" ?

      await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/add/data/tele`,
        data
      )
      :

      await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/add/data/sales`,
        data
      )
      console.log(res);
      setIsLoading(false);
      setResult({
        status: "success",
        title: "Data successfully pushed to Monday CRM",
      });
      setSelectedFiles([]);
      setDasboardId("");
    } catch (error) {
      setIsLoading(false);
      setResult({
        status: "error",
        title: "Failed to push data to Monday CRM",
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setResult(null);
      }, 5000);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content position-relative">
        {(isLoading || result) && (
          <div className="overlay">
            {isLoading ? (
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#fccd0a" }}
              />
            ) : (
              <Result status={result.status} title={result.title} />
            )}
          </div>
        )}
        <div className={isLoading || result ? "blur-effect" : ""}>
          <div className="mb-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Label for="phoneNumber">Please Enter Dashboard ID</Label>
            <Input
              type="number"
              id="dasboard_id"
              onChange={(e) => setDasboardId(e.target.value)}
              value={dasboardId}
            />

            <div className="flex-shrink-0">
              <select className="form-select" onChange={(e) => setDashboard(e.target.value)}>
                <option value="retention">Retention</option>
                <option value="sales">Sales</option>
                <option value="tele">Tele Sales</option>
              </select>
            </div>
          </div>
          <h6 className="card-title">Push Data to Monday CRM</h6>
          <CardSubtitle className="mb-4">
            Please Choose a CSV file to upload data on monday CRM
          </CardSubtitle>
          <Form>
            <Dropzone
              accept={{
                "text/csv": [],
              }}
              multiple={false}
              onDrop={(acceptedFiles) => {
                setSelectedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={URL.createObjectURL(f)}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{(f.size / 1024).toFixed(2)} KB</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ))}
            </div>
            <div
              className="mt-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                disabled={dasboardId ? false : true}
                onClick={handleAcceptedFiles}
                type="button"
                className="btn btn-success"
                value="Push Data"
              />
            </div>

            {/* <div
                        className="position-fixed top-0 end-0 p-3"
                        style={{ zIndex: "1005" }}
                    >
                        <Toast
                            isOpen={toast}
                            className="align-items-center text-white bg-success border-0 custom-toast"
                            role="alert"
                        >
                            <div className="d-flex">
                                <ToastBody>{successMessage}</ToastBody>
                                <button
                                    onClick={() => setToast(false)}
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                ></button>
                            </div>
                        </Toast>

                        <Toast
                            isOpen={toastError}
                            className="align-items-center text-white bg-danger border-0 custom-toast"
                            role="alert"
                        >
                            <div className="d-flex">
                                <ToastBody>{errorMessage}</ToastBody>
                                <button
                                    onClick={() => setToastError(false)}
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                ></button>
                            </div>
                        </Toast>
                    </div> */}
          </Form>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Article;
