import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";
import withRouter from "../../Common/withRouter";
import { createSelector } from "reselect";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import { useSelector } from "react-redux";

const ProfileMenu = (props: any) => {
  const [menu, setMenu] = useState(false);
  const [user, setUser] = useState<any>(null);

  const profile = (state: any) => state.Profile;
  const selectProfileProperties = createSelector(profile, (success) => success);

  const { success } = useSelector(selectProfileProperties);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      {
        const obj = JSON.parse(localStorage.getItem("authUser") || "");
        setUser(obj);
        console.log(obj, "obj");
      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user?.user?.profileImage ? user?.user?.profileImage : user1}
            alt="Header Avatar"
          />
          <span
            className="d-none d-xl-inline-block ms-2 me-1"
            style={{ color: "#232323" }}
          >
            {user?.user?.name?.firstName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/logout" className="dropdown-item">
            <i className="mx-1 bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(ProfileMenu));
