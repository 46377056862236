import React, { useEffect, useState } from "react";
import { Col, Container, Row, Alert } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { Scheduler } from "@aldabil/react-scheduler";
import axios from "axios";
import moment from "moment";

const WebinarCalender = () => {
  const [events, setEvents] = useState([]);
  const [copied, setCopied] = useState(false);
  const [notification, setNotification] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  console.log(events, "events");

  const copyWebinarLink = (webinarLink) => {
    navigator.clipboard
      .writeText(webinarLink)
      .then(() => {
        console.log("Webinar link copied to clipboard:", webinarLink);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
        // Optionally, show a success message or perform other actions
      })
      .catch((error) => {
        console.error("Failed to copy webinar link:", error);
        // Optionally, show an error message or handle the error
      });
  };

  const isFutureDate = (date) => {
    const now = new Date();
    const currentDate = new Date(date).getTime();
    return currentDate >= now.getTime();
  };

  const handleEventSubmit = async (
    event: any,
    action: string
  ): Promise<any> => {
    console.log("heeelooo");

    return new Promise(async (resolve, reject) => {
      // Your logic here
      console.log("heeelooo");

      // console.log(event, action);
      const startDate = new Date(event.start).getTime();
      const endDate = new Date(event.end).getTime();
      console.log(moment(startDate), "moment");

      if (!isFutureDate(startDate)) {
        setNotification("Cannot schedule events in the past date and time.");
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
        return;
      }

      // Calculate difference in milliseconds
      const differenceMs = endDate - startDate;

      // Convert milliseconds to minutes
      const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
      const webinarDetails = {
        topic: event.title,
        start: moment(event.start), // ISO 8601 format
        end: moment(event.end),
        duration: differenceMinutes, // Duration in minutes
        agenda: event.agenda,
        details: event.details,
        user: UserData?.user?._id,
        agendaAr: event.agendaAr,
        detailsAr: event.detailsAr,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/zoom`,
        webinarDetails
      );

      console.log(res, "res");

      console.log(event, "event");

      // Dummy resolve to match expected return type
      resolve(event);
    });
  };

  const getAllWebinars = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/zoom/getWebinars`
    );

    if (res.success) {
      // setEvents(res.data)

      const updatedData = res.data.map((webinars) => {
        console.log(webinars.start);

        // if (webinars.end < webinars.start) {
        return {
          event_id: webinars.id,
          title: webinars.agenda,
          start: new Date(webinars.start),
          end: new Date(webinars.end),
          start_url: webinars.start_url,
          join_url: webinars.join_url,
          registration_url: webinars.registration_url,
          details: webinars.details,
          event: webinars.id,
        };
        // }
      });
      console.log(updatedData, "updatedData");
      setEvents(updatedData);
    }
  };

  console.log(copied, "copied");

  useEffect(() => {
    getAllWebinars();
  }, []);

  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Webinars" breadcrumbItem="Full Calendar" />
          <Row className="justify-content-center">
            {copied && <div className="copied-message">Copied</div>}
            {showNotification && (
              <Alert color="danger" style={{ zIndex: "4000" }}>
                {notification}
              </Alert>
            )}
            <Col className="col-12 mt-3 mb-3">
              <Scheduler
                view="month"
                deletable={false}
                // editable={false}
                events={events}
                fields={[
                  {
                    name: "agenda",
                    type: "input",
                    config: { label: "Agenda" },
                  },
                  {
                    name: "details",
                    type: "input",
                    config: { label: "Details", multiline: true, rows: 4 },
                  },
                  {
                    name: "agendaAr",
                    type: "input",
                    config: { label: "اجندة" },
                  },
                  {
                    name: "detailsAr",
                    type: "input",
                    config: { label: "تفاصيل", multiline: true, rows: 4 },
                  },
                ]}
                viewerExtraComponent={(fields, event) => {
                  console.log(event.start_url);

                  return (
                    <div className="mt-2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          className="btn btn-success"
                          onClick={() => copyWebinarLink(event.join_url)}
                        >
                          {" "}
                          Copy Webinar Link
                        </button>

                        <a
                          className="btn btn-danger"
                          href={`${event.start_url}`}
                          target="_blank"
                        >
                          {" "}
                          Start Webinar
                        </a>
                      </div>
                    </div>
                  );
                }}
                onConfirm={handleEventSubmit}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default WebinarCalender;
