import React, { useEffect, useState } from "react";
import withRouter from "../../Components/Common/withRouter";
import { Space, Table } from "antd";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import axios from "axios";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
  },
  {
    title: "Submitted On",
    key: "createdOn",
    render: (_, record) => (
      <>
        <Moment date={record.createdOn} format="DD/M/y hh:mm a" />
      </>
    ),
  },
  {
    title: "Approved By",
    dataIndex: "requestHandledBy",
    key: "requestHandledBy",
  },
  {
    title: "Approved Amount",
    dataIndex: "amountApproved",
    key: "amountApproved",
  },
  {
    title: "Accepted Date",
    key: "acceptedDate",
    render: (_, record) => (
      <>
        <Moment date={record.acceptedDate} format="DD/M/y hh:mm a" />
      </>
    ),
  },
];

const Approved = () => {
  // Meta title
  document.title = "Approved Requests";

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const fetchBonusRequests = async (
    page,
    pageSize,
    from = "",
    to = "",
    search = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/Approved/bonus/requests`,
        {
          params: {
            page,
            limit: pageSize,
            from,
            to,
            search,
          },
        }
      );
      if (res.success) {
        setData(res.data);
        setPagination({
          current: res.currentPage,
          pageSize,
          total: res.totalPages * pageSize,
        });
      }
    } catch (error) {
      console.error("Failed to fetch bonus requests:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  function convertToCSV(arr) {
    // Create CSV headers
    const csvRows = [
      'Name,Email,Account Id,Submitted On,Approved By,Approved Amount, Accepted Date',
    ];

    // Map over the array of objects and create a row for each
    arr.forEach(item => {
      csvRows.push(`${item.fullName},${item.email},${item.accountId},${item.createdOn},${item.requestHandledBy},${item.amountApproved},${item.acceptedDate}`);
    });

    // Join each row with a newline character to create the CSV string
    return csvRows.join('\n');
  }

  function downloadCSV(e: any) {
    e.preventDefault()
    const csvString = convertToCSV(data);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `Bonus_approved_${formatDate(new Date())}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  }, [dateRange, searchTerm]);

  const handleTableChange = (pagination) => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  };

  const handleDateChange = (selectedDates, dateStr) => {
    setDateRange({
      from: selectedDates[0]
        ? moment(selectedDates[0]).format("YYYY-MM-DD")
        : "",
      to: selectedDates[1] ? moment(selectedDates[1]).format("YYYY-MM-DD") : "",
      fullDate: dateStr,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Bonus"
            breadcrumbItem="Approved Profit Requests"
          />
          <Row className="mb-3 justify-content-between">
            <Col lg="5">
              <div className="align-self-center">
                <label> Search By Date Range</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Please select a range to fetch approved requests"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                  }}
                  onClose={handleDateChange}
                  id="default-picker"
                />
              </div>
            </Col>
            <Col lg="5">
              <div>
                <label htmlFor="example-search-input" className="mt-2">
                  Search
                </label>
                <div className="d-flex gap-2" >
                  <input
                    // style={{ width: "100%" }}
                    className="form-control"
                    type="search"
                    placeholder="Search By Name, Email Or account"
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />

                  <button
                    type="button"
                    disabled={!loading ? false : true}
                    className="btn btn-success"
                    id="btn-save-booking"
                    onClick={(e) => downloadCSV(e)}
                  > Export</button>
                </div>

              </div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            pagination={(dateRange.from == "" ) ? {
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) =>
                handleTableChange({ current: page, pageSize }),
            } : false}
            loading={loading}
            rowKey="_id"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Approved);
