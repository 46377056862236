import React, { useEffect, useState } from "react";
import "./App.css";
import { authProtectedRoutes, publicRoutes } from "./Routes/allRoutes";
import { Route, Routes } from "react-router-dom";
import VerticalLayout from "./Layouts/VerticalLayout";
import HorizotanlLayout from "./Layouts/HorizontalLayout/index";
import "./assets/scss/theme.scss";
import NonAuthLayout from "./Layouts/NonLayout";
import "../src/assets/scss/custom/pages/_quickReplies.scss";
import "../src/assets/scss/custom/components/liveChat.css";
import { TbDevicesX } from "react-icons/tb";
//constants
import { LAYOUT_TYPES } from "./Components/constants/layout";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import AuthProtected from "Routes/AuthProtected";
import withRouter from "Components/Common/withRouter";
import "react-toastify/dist/ReactToastify.css";

// Activating fake backend
fakeBackend();

const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout;
      break;
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout;
      break;
    default:
      break;
  }
  return Layout;
};

function App(props: any) {
  // const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);

  const User = JSON.parse(localStorage.getItem("authUser"));
  const sessionID = localStorage.getItem("session_id");

  // useEffect(() => {
  //   const handleResize = () => setIsDesktop(window.innerWidth > 992);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  console.log(sessionID, "sessionId");

  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  );
  const { layoutTypes } = useSelector(selectLeadData);

  const Layout = getLayout(layoutTypes);

  useEffect(() => {
    if (User) {
      if (!User.token) {
        props.router.navigate("/login");
      }
    } else {
      props.router.navigate("/login");
    }
  }, [User]);

  // conditional rendering based on isDesktop state
  // if (!isDesktop) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         flexDirection: "column",
  //         gap: "13px",
  //         height: "100vh",
  //         fontSize: "18px",
  //         color: "#232323",
  //       }}
  //     >
  //       <TbDevicesX style={{ fontSize: "100px", color: "#fccd0a" }} />
  //       <p>Not supported on mobile or tablet!</p>
  //     </div>
  //   );
  // }

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            key={idx}
            element={
              <React.Fragment>
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              </React.Fragment>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default withRouter(App);
