import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
  ToastHeader,
  ToastBody,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams, useNavigate } from "react-router-dom";
import { Editor as MyEditor } from "draft-js";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "draft-js/dist/Draft.css";
// import images
import img1 from "../../assets/images/small/img-2.jpg";
import avtar1 from "../../assets/images/users/avatar-2.jpg";
import Spinners from "Components/Common/Spinner";
import axios from "axios";
import Moment from "react-moment";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const BlogDetails = () => {
  interface Blog {
    // Define the properties of a blog object here
    _id: string;
    title: string;
    slug: string;
    image: string;
    publishDate: Date;
    content: any;
    isPublished: boolean;
    createdOn: Date;
    category: any;
    language: any;
    // ... other properties ...
  }

  const [detail, setDetail] = useState<Blog>();
  const [modal, setmodal] = useState<boolean>(false);
  const [content, setContent] = useState(null) as any;
  const [loading, setLoading] = useState(true);
  const [popovertop, setpopovertop] = useState<boolean>(false);
  const [popoverdismiss, setpopoverdismiss] = useState<boolean>(false);
  const [selectedGroup, setselectedGroup] = useState(null) as any[];
  const [opt, setOpt] = useState(null) as any[];
  const [image, setImage] = useState(null) as any[];
  const [cats, setCats] = useState(null) as any[];
  const [selectedGroupCat, setselectedGroupCat] = useState(null) as any[];
  const [activeTab, setActiveTab] = useState("1");
  const [title, setTitle] = useState(null) as any[];
  const [slug, setSlug] = useState(null) as any[];
  const [errorMessage, setErrorMessage] = useState(null) as any[];
  const [successMessage, setSuccessMessage] = useState(null) as any[];
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [editorState, setEditorState] = useState<any>(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState<any>(() =>
    EditorState.createEmpty()
  );
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id, "_id");
  const user = JSON.parse(localStorage.getItem("authUser"));

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  };

  const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const fetchdetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/one/blog/admin/${id}`
    );
    if (res.success) {
      setDetail(res.data);
      console.log(res.data?.content, "str");
      console.log(JSON.parse(res.data?.content), "hello");
      const contentState = convertFromRaw(JSON.parse(res.data?.content));
      const editorState = EditorState.createWithContent(
        contentState,
        decorator
      );
      // console.log(editorState, "<=====");
      setEditorState(editorState);
      setContent(editorState);

      setselectedGroupCat({
        label: res.data?.category?.title,
        value: res.data?.category?._id,
      });
      setTitle(res?.data?.title);
      setSlug(res?.data?.slug);
      setLoading(false);
    }
  };

  const handleUpload = async (e) => {
    console.log(e.target.files[0], "selectedFiles");

    if (e.target.files) {
      const file = e.target.files[0];
      let _id = "1";
      console.log(file);
      const formData = new FormData();
      formData.append("files[]", file);
      formData.append("action", "attachments");
      formData.append("_id", _id);

      axios
        .post(`${process.env.REACT_APP_APIKEY}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setSuccessMessage(
            "Image added successfully. Now Please add the content for blog to continue."
          );
          console.log(data.result.file_path, "file path");
          setImage(data.result.file_path);
        })
        .catch(function (error) {
          return { error: { message: error } };
        });
    } else {
      setErrorMessage("Please select Image to Upload.");
      setToastError(true);
    }
  };

  console.log(editorState);

  const publishBlog = async () => {
    setLoading(true);
    const data = {
      id: id,
      isPublished: true,
    };
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_APIKEY}/api/edit/blog`,
      data,
      { headers }
    );
    if (res.success) {
      setDetail(res.data);
      setLoading(false);
    } else {
      console.log(res.message);
      setLoading(false);
    }
  };

  const addToDraft = async () => {
    setLoading(true);
    const data = {
      id: id,
      isPublished: false,
    };
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_APIKEY}/api/edit/blog`,
      data,
      { headers }
    );
    if (res.success) {
      setDetail(res.data);
      setLoading(false);
    } else {
      console.log(res.message);
      setLoading(false);
    }
  };

  const deleteBlog = async () => {
    setLoading(true);
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.delete(
      `${process.env.REACT_APP_APIKEY}/api/delete/blog/${id}`,
      { headers }
    );
    if (res.success) {
      navigate("/blog-grid");
      setLoading(false);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  const optionGroup = [
    {
      label: "Languages",
      options: [
        { label: "Arabic", value: "ar" },
        { label: "English", value: "en" },
      ],
    },
  ];

  const optionGroup2 = [
    {
      label: "Categories",
      options: opt,
    },
  ];

  const fetchCat = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/categories`,
      { headers }
    );
    if (res.success) {
      //   setCat(res.data)
      if (res.data?.length) {
        let options = [];
        res.data?.map((dat) => {
          options.push({ label: dat.title, value: dat._id });
        });
        setOpt(options);
        setCats(res.data);
      } else {
        optionGroup2[0].label = "No Categories Available";
        optionGroup2[0].options = [];
      }
    } else {
      console.log("Server Error");
    }
  };

  useEffect(() => {
    if (detail) {
      const contentState = convertFromRaw(
        JSON.parse(activeTab === "1" ? detail?.content : detail?.content)
      );
      const editorState = EditorState.createWithContent(contentState);
      setContent(editorState);
    }
    if (cats) {
      let options = [];
      cats.map((dat) => {
        options.push({
          label: activeTab === "1" ? dat.title : dat.title,
          value: dat._id,
        });
      });
      setOpt(options);
    }
  }, [activeTab]);

  function handleSelectGroup(selectedGroup: any) {
    setselectedGroup(selectedGroup);
  }

  function handleSelectGroupCat(selectedGroup: any) {
    setselectedGroupCat(selectedGroup);
  }

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const onEditorStateChange2 = (newEditorState2: EditorState) => {
    setEditorState2(newEditorState2);
  };

  const saveContent = async () => {
    if (!selectedGroupCat) {
      setErrorMessage("Please Select Category");
      setToastError(true);
    }
    if (!title) {
      setErrorMessage("Please Add Title in English");
      setToastError(true);
    }
    if (!slug) {
      setErrorMessage("Please Add Slug in English");
      setToastError(true);
    } else {
      const contentState = editorState.getCurrentContent();
      const contentRaw = convertToRaw(contentState);

      console.log(contentRaw, "contentRaw edit");

      const data = {
        id: id,
        title: title,
        slug: slug,
        content: JSON.stringify(contentRaw),
        category: selectedGroupCat.value,
      };

      console.log(data.content, "stringify edit");
      if (image) {
        Object.assign(data, { image: image, oldImage: detail.image });
      }
      const headers = {
        Authentication: `Bearer ${user?.token}`,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/edit/blog`,
        data,
        { headers }
      );
      if (response.success) {
        setDetail(response.data);
        if (response.data?.content) {
          const contentState = convertFromRaw(
            JSON.parse(
              activeTab === "1"
                ? response.data?.content
                : response.data?.content
            )
          );
          const editorState = EditorState.createWithContent(contentState);
          setContent(editorState);

          const contentStateEn = convertFromRaw(
            JSON.parse(response.data?.content)
          );
          const editorStateEn = EditorState.createWithContent(contentStateEn);
          setEditorState(editorStateEn);

          //   const contentStateAr = convertFromRaw(
          //     JSON.parse(response.data?.contentAr)
          //   );
          //   const editorStateAr = EditorState.createWithContent(contentStateAr);
          //   setEditorState2(editorStateAr);
        }

        setTitle(response.data?.title);
        setSlug(response.data?.slug);
        setmodal(false);
        setSuccessMessage("Blog is edited Successfully!");
        setToast(true);
      } else {
        setErrorMessage(
          response?.message?.message
            ? response?.message?.message
            : response?.message
        );
        setToastError(true);
      }
      console.log("Saved content:", contentRaw);
    }

    // You can save contentRaw to your state or database for later retrieval
  };

  useEffect(() => {
    fetchdetails();
    fetchCat();
  }, []);

  useEffect(() => {
    fetchdetails();
    fetchCat();
  }, []);

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(false);
        setSuccessMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
    if (toastError) {
      const timer = setTimeout(() => {
        setToastError(false);
        setErrorMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [toast, toastError]);

  // useEffect(() => {
  //   if ( editorState) {
  //     const htmlContent = '<p>This is some <a href="https://example.com">link</a> text.</p>';

  //     // Convert the HTML content to ContentState.
  //     const blocksFromHTML = convertFromHTML(htmlContent);
  //     const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

  //     // Set the ContentState in the editor state.
  //     this.setState({
  //       editorState: EditorState.createWithContent(contentState),
  //     });

  //   }
  // },[editorState, editorState2])

  const ImageComponent = ({ src }) => (
    <img src={src} alt="" style={{ maxWidth: "100%" }} />
  );

  const mediaBlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      return {
        component: MediaComponent,
        editable: false,
      };
    }

    return null;
  };

  const MediaComponent = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const type = entity.getType();
    const data = entity.getData();

    if (type === "IMAGE") {
      return <ImageComponent src={data.src} />;
    }

    return null;
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "content",
          alignItems: "center",
          height: "100%",
          width: "100%",
          backgroundColor: "red",
        }}
      >
        <Spinners setLoading={setLoading} />
      </div>
    );
  }
  //meta title
  document.title = "Blog Details | Skote - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Details" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <Row className="">
                      <Col xl={8}>
                        <div>
                          <div className="text-center">
                            {/* <div>
                              <ul
                                className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                                role="tablist"
                              >
                                <NavItem>
                                  <NavLink
                                    to="#"
                                    className={classnames({
                                      active: activeTab === "1",
                                    })}
                                    onClick={() => {
                                      setActiveTab("1");
                                    }}
                                  >
                                    English
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    to="#"
                                    className={classnames({
                                      active: activeTab === "2",
                                    })}
                                    onClick={() => {
                                      setActiveTab("2");
                                    }}
                                  >
                                    Arabic
                                  </NavLink>
                                </NavItem>
                              </ul>
                            </div> */}
                            <div
                              className="mb-4 mt-2"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {detail?.isPublished ? (
                                <button
                                  onClick={addToDraft}
                                  className="btn btn-soft-primary waves-effect waves-light btn btn-secondary"
                                >
                                  Add to draft
                                </button>
                              ) : (
                                <button
                                  className="btn btn-soft-primary waves-effect waves-light btn btn-secondary"
                                  onClick={publishBlog}
                                >
                                  Publish Blog
                                </button>
                              )}
                              <i
                                onClick={() => setmodal(!modal)}
                                className="fas fa-edit icon-hover-edit mx-3"
                                style={{ fontSize: 18, color: "#556EE7" }}
                              ></i>
                              <Modal
                                size="lg"
                                isOpen={modal}
                                autoFocus={true}
                                centered={true}
                                toggle={() => {
                                  setmodal(!modal);
                                }}
                              >
                                <div className="modal-content">
                                  <ModalHeader
                                    toggle={() => {
                                      setmodal(!modal);
                                    }}
                                  >
                                    Edit Blog{" "}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Row>
                                      <Form>
                                        <Card>
                                          <CardBody>
                                            <div className="my-5 text-center ">
                                              <img
                                                src={
                                                  image
                                                    ? image
                                                    : `${detail?.image}`
                                                }
                                                alt={detail?.title}
                                                className="img-thumbnail mx-auto d-block"
                                              />
                                              <Input
                                                onChange={handleUpload}
                                                multiple={false}
                                                accept="image/*"
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                              />
                                            </div>
                                            <Row className="mb-3">
                                              <label
                                                htmlFor="language"
                                                className="col-md-4 col-form-label"
                                              >
                                                Select Category
                                              </label>
                                              <div className="col-md-8">
                                                <Select
                                                  value={selectedGroupCat}
                                                  onChange={
                                                    handleSelectGroupCat
                                                  }
                                                  id="language"
                                                  options={optionGroup2}
                                                  className="select2-selection"
                                                />
                                              </div>
                                            </Row>

                                            <Row className="mb-3">
                                              <label
                                                htmlFor="title"
                                                className="col-md-2 col-form-label"
                                              >
                                                Title
                                              </label>
                                              <div className="col-md-10">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  id="title"
                                                  value={title}
                                                  placeholder="Write the blog title here"
                                                  onChange={(e) =>
                                                    setTitle(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Row>
                                            <Row className="mb-3">
                                              <label
                                                htmlFor="slug"
                                                className="col-md-2 col-form-label"
                                              >
                                                Slug
                                              </label>
                                              <div className="col-md-10">
                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  id="slug"
                                                  value={slug}
                                                  placeholder="Write the blog slug here"
                                                  onChange={(e) =>
                                                    setSlug(e.target.value)
                                                  }
                                                />
                                              </div>
                                            </Row>
                                            <div>
                                              <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                editorState={editorState}
                                                onEditorStateChange={
                                                  onEditorStateChange
                                                }
                                                // handlePastedText={ () => false}

                                                toolbar={{
                                                  // options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'emoji', 'image', 'remove', 'history'],
                                                  // inline: {
                                                  //   options: ['bold', 'italic', 'underline'],
                                                  // },
                                                  textAlign: {
                                                    inDropdown: true,
                                                  },
                                                  link: { inDropdown: true },
                                                }}
                                              />
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </Form>
                                    </Row>
                                  </ModalBody>
                                  <ModalFooter>
                                    <Button
                                      type="button"
                                      color="secondary"
                                      onClick={() => {
                                        setmodal(!modal);
                                      }}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      type="button"
                                      color="primary"
                                      onClick={saveContent}
                                      // onClick={addCategory}
                                    >
                                      {" "}
                                      Save <i className="fas fa-save ms-1"></i>{" "}
                                    </Button>
                                  </ModalFooter>
                                </div>
                              </Modal>

                              <div
                                className="position-fixed top-0 end-0 p-3"
                                style={{ zIndex: "1005" }}
                              >
                                <Toast
                                  isOpen={toast}
                                  className="align-items-center text-white bg-success border-0 custom-toast"
                                  role="alert"

                                  // style={{ backgroundColor: "green" }}
                                >
                                  <div className="d-flex">
                                    <ToastBody>{successMessage}</ToastBody>
                                    <button
                                      onClick={() => setToast(false)}
                                      type="button"
                                      className="btn-close btn-close-white me-2 m-auto"
                                    ></button>
                                  </div>
                                </Toast>

                                <Toast
                                  isOpen={toastError}
                                  className="align-items-center text-white bg-danger border-0 custom-toast"
                                  role="alert"

                                  // style={{ backgroundColor: "green" }}
                                >
                                  <div className="d-flex">
                                    <ToastBody>{errorMessage}</ToastBody>
                                    <button
                                      onClick={() => setToastError(false)}
                                      type="button"
                                      className="btn-close btn-close-white me-2 m-auto"
                                    ></button>
                                  </div>
                                </Toast>
                              </div>
                              <i
                                id="Popovertop"
                                onClick={() => {
                                  setpopoverdismiss(!popoverdismiss);
                                }}
                                className="fas fa-trash-alt icon-hover-del"
                                style={{ fontSize: 18, color: "#556EE7" }}
                              ></i>
                              <Popover
                                placement="bottom"
                                isOpen={popovertop}
                                target="Popovertop"
                                toggle={() => {
                                  setpopovertop(!popovertop);
                                }}
                              >
                                <PopoverHeader>Are you sure?</PopoverHeader>
                                <PopoverBody>
                                  <p>
                                    This change will be permanent and connot be
                                    reverted.
                                  </p>

                                  <button
                                    onClick={() => {
                                      setpopovertop(!popovertop);
                                    }}
                                    className="btn btn-secondary"
                                  >
                                    cancel
                                  </button>
                                  <button
                                    onClick={deleteBlog}
                                    className="btn btn-danger mx-2"
                                  >
                                    Delete
                                  </button>
                                </PopoverBody>
                              </Popover>
                            </div>
                            <h4>{detail?.title}</h4>
                            <p className="text-muted mb-sm-4 mb-3 mt-sm-0 mt-4">
                              <i className="mdi mdi-calendar me-1"></i>
                              <Moment
                                date={detail?.publishDate}
                                format="Do MMMM YYYY"
                              />
                            </p>
                          </div>

                          <hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={3} xs={6}>
                                <div>
                                  <p className="text-muted mb-2">Categories</p>
                                  <h5 className="font-size-15">
                                    {activeTab === "1"
                                      ? detail?.category?.title
                                      : detail?.category?.title}
                                  </h5>
                                </div>
                              </Col>
                              <Col sm={3} xs={6}>
                                <div>
                                  <p className="text-muted mb-2">Language</p>
                                  <h5 className="font-size-15">
                                    {activeTab === "1"
                                      ? detail?.language
                                      : detail?.language}
                                  </h5>
                                </div>
                              </Col>
                              <Col sm={3} xs={6}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Date</p>
                                  <h5 className="font-size-15">
                                    <Moment
                                      date={detail?.publishDate}
                                      format="Do MMMM YYYY"
                                    />
                                  </h5>
                                </div>
                              </Col>
                              <Col sm={3} xs={6}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Post by</p>
                                  <h5 className="font-size-15">
                                    {detail?.language === "ar"
                                      ? "برايم اكس بروكر"
                                      : "PrimeX Broker"}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <div className="my-5">
                            <img
                              src={`${detail?.image}`}
                              alt={detail?.title}
                              className="img-thumbnail mx-auto d-block"
                            />
                          </div>
                          <hr />
                          <div className="mt-4">
                            <div>
                              <MyEditor
                                editorState={content}
                                readOnly={true}
                                blockRendererFn={mediaBlockRenderer}
                              />

                              {/* <p className="mb-4">
                                Ut enim ad minima veniam, quis nostrum
                                exercitationem ullam corporis suscipit laboriosam,
                                nisi ut aliquid ex ea reprehenderit qui in ea
                                voluptate velit esse quam nihil molestiae
                                consequatur, vel illum qui dolorem eum fugiat quo
                                voluptas nulla pariatur? At vero eos et accusamus et
                                iusto odio dignissimos ducimus qui blanditiis
                                praesentium voluptatum deleniti atque corrupti quos
                                dolores et quas molestias excepturi sint occaecati
                                cupiditate non provident, similique sunt
                              </p> */}

                              {/* <blockquote className="p-4 border-light border rounded mb-4">
                                <div className="d-flex">
                                  <div className="me-3">
                                    <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                  </div>
                                  <div>
                                    <p className="mb-0">

                                      At vero eos et accusamus et iusto odio
                                      dignissimos ducimus qui blanditiis praesentium
                                      deleniti atque corrupti quos dolores et quas
                                      molestias excepturi sint quidem rerum facilis
                                      est
                                    </p>
                                  </div>
                                </div>
                              </blockquote> */}

                              {/* <p>
                                Itaque earum rerum hic tenetur a sapiente delectus,
                                ut aut reiciendis voluptatibus maiores alias
                                consequatur aut perferendis doloribus asperiores
                                repellat. Sed ut perspiciatis unde omnis iste natus
                                error sit
                              </p> */}

                              {/* <div className="mt-4">
                                <h5 className="mb-3">Title: </h5>

                                <div>
                                  <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                      <div>
                                        <ul className="ps-4">
                                          <li className="py-1">
                                            Donec sodales sagittis
                                          </li>
                                          <li className="py-1">
                                            Sed consequat leo eget
                                          </li>
                                          <li className="py-1">
                                            Aliquam lorem ante
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                      <div>
                                        <ul className="ps-4">
                                          <li className="py-1">
                                            Aenean ligula eget
                                          </li>
                                          <li className="py-1">
                                            Cum sociis natoque
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>

                            <hr />

                            {/* <div className="mt-5">
                              <h5 className="font-size-15">
                                <i className="bx bx-message-dots text-muted align-middle me-1"></i>
                                Comments :
                              </h5>

                              <div>
                                <div className="d-flex py-3">
                                  <div className="avatar-xs me-3">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      <i className="bx bxs-user"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Delores Williams
                                      <small className="text-muted float-end">
                                        1 hr Ago
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      If several languages coalesce, the grammar of
                                      the resulting language is more simple and
                                      regular than that of the individual
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex py-3 border-top">
                                  <div className="avatar-xs me-3">
                                    <img
                                      src={avtar1}
                                      alt=""
                                      className="img-fluid d-block rounded-circle"
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Clarence Smith
                                      <small className="text-muted float-end">
                                        2 hrs Ago
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      Neque porro quisquam est, qui dolorem ipsum
                                      quia dolor sit amet
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>

                                    <div className="d-flex pt-3">
                                      <div className="avatar-xs me-3">
                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                          <i className="bx bxs-user"></i>
                                        </div>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14 mb-1">
                                          Silvia Martinez
                                          <small className="text-muted float-end">
                                            2 hrs Ago
                                          </small>
                                        </h5>
                                        <p className="text-muted">
                                          To take a trivial example, which of us
                                          ever undertakes laborious physical
                                          exercise
                                        </p>
                                        <div>
                                          <Link to="#" className="text-success">
                                            <i className="mdi mdi-reply"></i> Reply
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex py-3 border-top">
                                  <div className="avatar-xs me-3">
                                    <div className="avatar-title rounded-circle bg-light text-primary">
                                      <i className="bx bxs-user"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h5 className="font-size-14 mb-1">
                                      Keith McCoy
                                      <small className="text-muted float-end">
                                        12 Aug
                                      </small>
                                    </h5>
                                    <p className="text-muted">
                                      Donec posuere vulputate arcu. phasellus
                                      accumsan cursus velit
                                    </p>
                                    <div>
                                      <Link to="#" className="text-success">
                                        <i className="mdi mdi-reply"></i> Reply
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* <div className="mt-4">
                              <h5 className="font-size-16 mb-3">Leave a Message</h5>

                              <Form>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="commentname-input">
                                        Name
                                      </Label>
                                      <Input
                                        type="text"
                                        id="commentname-input"
                                        placeholder="Enter name"
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label htmlFor="commentemail-input">
                                        Email
                                      </Label>
                                      <Input
                                        type="email"
                                        id="commentemail-input"
                                        placeholder="Enter email"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-3">
                                  <Label htmlFor="commentmessage-input">
                                    Message
                                  </Label>
                                  <Input tag="textarea"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    rows={3}
                                  ></Input>
                                </div>

                                <div className="text-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="w-sm"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Form>
                            </div> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BlogDetails;
