import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../../Components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";

//import components
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "../../../slices/jobs/thunk";

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "./JobListCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  Button,
} from "reactstrap";

//flatpickr
import "flatpickr/dist/themes/material_green.css";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";

function JobList() {
  //meta title
  document.title = "Jobs List | PrimeX";

  const dispatch = useDispatch<any>();

  const addJob = async (add) => {
    return await axios.post(`${process.env.REACT_APP_APIKEY}/api/add/job`, add);
  };

  const selectProperties = createSelector(
    (state: any) => state.jobs,
    (jobs) => ({
      jobs: jobs?.jobList,
      loading: jobs?.loading,
    })
  );
  const { jobs, loading } = useSelector(selectProperties);
  const [isLoading, setLoading] = useState<boolean>(loading);

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [job, setJob] = useState<any>(null);
  const [jobList, setJobList] = useState<any>();
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      title: (job && job.title) || "",
      companyName: (job && job.companyName) || "",
      location: (job && job.location) || "",
      experience: (job && job.experience) || "",
      position: (job && job.position) || "",
      type: (job && job.type) || "",
      department: (job && job.department) || "",
      status: (job && job.status) || "",
      description: (job && job.description) || "",
    },
    validationSchema: Yup.object({
      jobId: Yup.string().required("Please Enter Your Job Id"),
      title: Yup.string().required("Please Enter Your Job Title"),
      location: Yup.string().required("Please Enter Your Location"),
      experience: Yup.string().required("Please Enter Your Experience"),
      position: Yup.number().required("Please Enter Your Position"),
      type: Yup.string().required("Please Enter Your Type"),
      department: Yup.string().required("Please Enter Your Department"),
      status: Yup.string().required("Please Enter Your Status"),
      //   description: Yup.string().required("Please Enter the Job Description"),
    }),
    onSubmit: async (values: any) => {
      console.log("Form submitted with values:", values);
      const contentState = editorState.getCurrentContent();
      const description = JSON.stringify(convertToRaw(contentState));

      if (isEdit) {
        const updateJobList = {
          _id: job._id,
          jobId: values.jobId,
          title: values.title,
          location: values.location,
          experience: values.experience,
          position: values.position,
          type: values.type,
          department: values.department,
          status: values.status,
          description: description,
          postedBy: "PrimeX Broker",
        };
        console.log("Updating job:", updateJobList);
        console.log("Updating job state:", jobList);
        const resultAction = await dispatch(onUpdateJobList(updateJobList));
        if (onUpdateJobList.fulfilled.match(resultAction)) {
          const updatedJob = resultAction.payload;
          setJobList((prevJobList) =>
            prevJobList.map((job) =>
              job._id === updatedJob._id ? updatedJob : job
            )
          );
        }
        validation.resetForm();
        setEditorState(EditorState.createEmpty());
      } else {
        const contentState = editorState.getCurrentContent();
        const contentRaw = convertToRaw(contentState);
        const newJobList = {
          jobId: values["jobId"],
          title: values["title"],
          location: values["location"],
          experience: values["experience"],
          position: values["position"],
          type: values["type"],
          department: values["department"],
          status: values["status"],
          description: description,
          postedBy: "PrimeX Broker",
        };
        // save new Job
        const res = await addJob(newJobList);
        setJobList([...jobList, res.data]);
        console.log(res, "job res");
        validation.resetForm();
        setEditorState(EditorState.createEmpty());
      }
      toggle();
    },
  });

  useEffect(() => {
    if (jobs && !jobs.length) {
      dispatch(onGetJobList());
    }
  }, [dispatch, jobs]);

  useEffect(() => {
    setJobList(jobs);
  }, [jobs]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setEditorState(EditorState.createEmpty());
      setJob(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleJobClick = useCallback(
    (arg: any) => {
      const job = arg;
      const contentState = convertFromRaw(JSON.parse(job.description || "{}"));
      setEditorState(EditorState.createWithContent(contentState));
      setJob({
        _id: job._id,
        jobId: job.jobId,
        title: job.title,
        location: job.location,
        experience: job.experience,
        position: job.position,
        type: job.type,
        department: job.department,
        status: job.status,
        description: job.description,
        postedBy: job.postedBy,
      });

      setIsEdit(true);

      toggle();
    },
    [toggle]
  );

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (job) => {
    setJob(job);
    setDeleteModal(true);
  };

  const handleDeletejob = async (jobId: any) => {
    dispatch(onDeleteJobList(jobId))
      .then(() => {
        const updatedJobs = jobList.filter((job) => job._id !== jobId);
        setJobList(updatedJobs);
        console.log("Job deleted successfully");
      })
      .catch((error) => {
        console.error("Failed to delete job:", error);
      });
    setDeleteModal(false);
  };

  const handleJobClicks = () => {
    setIsEdit(false);
    setJob(null);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "No",
        accessor: "jobId",
        Filter: false,
        filterable: true,
        disableSortBy: true,
        Cell: (cellProps: any) => {
          return <JobNo {...cellProps} />;
        },
      },
      {
        Header: "Job Title",
        accessor: "title",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return <JobTitle {...cellProps} />;
        },
      },

      {
        Header: "Location",
        accessor: "location",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return <Location {...cellProps} />;
        },
      },
      {
        Header: "Experience",
        accessor: "experience",
        Filter: false,
        filterable: true,
        Cell: (cellProps: any) => {
          return <Experience {...cellProps} />;
        },
      },
      {
        Header: "Position",
        accessor: "position",
        Filter: false,
        Cell: (cellProps: any) => {
          return <Position {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: false,
        Cell: (cellProps: any) => {
          return <Type {...cellProps} />;
        },
      },
      {
        Header: "Posted Date",
        accessor: "createdAt",
        Filter: false,
        Cell: (cellProps: any) => {
          return <PostedDate {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        disableFilters: true,
        Cell: (cellProps: any) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to={`/job-details/${cellProps.row.original._id}`}
                  className="btn btn-sm btn-soft-primary"
                >
                  <i
                    className="mdi mdi-eye-outline"
                    id={`viewtooltip-${cellProps.row.original._id}`}
                  ></i>
                </Link>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original._id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const jobData = cellProps.row.original;
                    handleJobClick(jobData);
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original;
                    onClickDelete(jobData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleJobClick]
  );

  const fetchJobs = async () => {
    const res = await axios.get(`${process.env.REACT_APP_APIKEY}/api/get/jobs`);
    if (res.success) {
      setJobList(res.data);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeletejob(job._id)}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Jobs" breadcrumbItem="Jobs Lists" />
          <Row>
            <Col lg={12}>
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <Card>
                  <CardBody className="border-bottom">
                    <div className="d-flex align-items-center">
                      <CardTitle tag="h5" className="mb-0 flex-grow-1">
                        Jobs Lists
                      </CardTitle>
                      <div className="flex-shrink-0">
                        <Link
                          to="#"
                          onClick={() => handleJobClicks()}
                          className="btn btn-primary me-1"
                        >
                          Add New Job
                        </Link>
                        <Link to="#" className="btn btn-light me-1">
                          <i className="mdi mdi-refresh"></i>
                        </Link>
                        {/* <UncontrolledDropdown className="dropdown d-inline-block me-1">
                                                        <DropdownToggle type="menu" className="btn btn-success" id="dropdownMenuButton1">
                                                            <i className="mdi mdi-dots-vertical"></i></DropdownToggle>
                                                        <DropdownMenu>
                                                            <li><DropdownItem href="#">Action</DropdownItem></li>
                                                            <li><DropdownItem href="#">Another action</DropdownItem></li>
                                                            <li><DropdownItem href="#">Something else here</DropdownItem></li>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown> */}
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={jobList || []}
                      customPageSize={8}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      isJobListGlobalFilter={true}
                      isPagination={true}
                      isShowingPageLength={true}
                      customPageSizeOption={true}
                      handleJobClicks={handleJobClicks}
                      tableClass="table-bordered align-middle nowrap mt-2"
                      paginationDiv="col-12 col-sm-7 mt-sm-0 mt-3"
                      pagination="pagination justify-content-sm-end justify-content-center pagination-rounded mb-0"
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Job" : "Add Job"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label> Job Id</Label>
                      <Input
                        name="jobId"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.jobId || ""}
                        invalid={
                          validation.touched.jobId && validation.errors.jobId
                            ? true
                            : false
                        }
                      />
                      {validation.touched.jobId && validation.errors.jobId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.jobId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Job Title</Label>
                      <Input
                        name="title"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title
                            ? true
                            : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>

                    {/* <div className="mb-3">
                                            <Label>Location</Label>
                                            <Input
                                                name="location"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.location || ""}
                                                invalid={
                                                    validation.touched.location && validation.errors.location ? true : false
                                                }
                                            />
                                            {validation.touched.location && validation.errors.location ? (
                                                <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                                            ) : null}
                                        </div> */}
                    <div className="mb-3">
                      <Label>Location</Label>
                      <Input
                        name="location"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.location || ""}
                        invalid={
                          validation.touched.location &&
                          validation.errors.location
                            ? true
                            : false
                        }
                      >
                        <option> Select Country</option>
                        <option value="Dubai">Dubai</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Remote">Remote</option>
                      </Input>
                      {validation.touched.type && validation.errors.location ? (
                        <FormFeedback type="invalid">
                          {validation.errors.location}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Experience</Label>
                      <Input
                        name="experience"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.experience || ""}
                        invalid={
                          validation.touched.experience &&
                          validation.errors.experience
                            ? true
                            : false
                        }
                      />
                      {validation.touched.experience &&
                      validation.errors.experience ? (
                        <FormFeedback type="invalid">
                          {validation.errors.experience}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Position</Label>
                      <Input
                        name="position"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.position || ""}
                        invalid={
                          validation.touched.position &&
                          validation.errors.position
                            ? true
                            : false
                        }
                      />
                      {validation.touched.position &&
                      validation.errors.position ? (
                        <FormFeedback type="invalid">
                          {validation.errors.position}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Type</Label>
                      <Input
                        name="type"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.type || ""}
                        invalid={
                          validation.touched.type && validation.errors.type
                            ? true
                            : false
                        }
                      >
                        <option>Select Type</option>
                        <option value="Full Time">Full Time</option>
                        <option value="Part Time">Part Time</option>
                        <option value="Freelance">Freelance</option>
                        <option value="Internship">Internship</option>
                      </Input>
                      {validation.touched.type && validation.errors.type ? (
                        <FormFeedback type="invalid">
                          {validation.errors.type}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Status</Label>
                      <Input
                        name="status"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.status || ""}
                        invalid={
                          validation.touched.status && validation.errors.status
                            ? true
                            : false
                        }
                      >
                        <option>Select Status</option>
                        <option value={"Active"}>Active</option>
                        <option value={"New"}>New</option>
                        <option value={"Close"}>Close</option>
                      </Input>
                      {validation.touched.status && validation.errors.status ? (
                        <FormFeedback status="invalid">
                          {validation.errors.status}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Department</Label>
                      <Input
                        name="department"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.department || ""}
                        invalid={
                          validation.touched.department &&
                          validation.errors.department
                            ? true
                            : false
                        }
                      >
                        <option>Select Department</option>
                        <option value="Marketing Department">
                          Marketing Department
                        </option>
                        <option value="IT Department">IT Department</option>
                        <option value="Support Department">
                          Support Department
                        </option>
                        <option value="Sales Department">
                          Sales Department
                        </option>
                      </Input>
                      {validation.touched.department &&
                      validation.errors.department ? (
                        <FormFeedback department="invalid">
                          {validation.errors.department}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Description</Label>
                      <div>
                        <Editor
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-user"
                      >
                        {" "}
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

export default JobList;
