import React, { useState } from "react";
import MyContext from "./MyContext";
import PropTypes from "prop-types";

function MyProvider({ children }) {
  const [lang, setLang] = useState<any>("en");

  return (
    <MyContext.Provider
      value={{
        lang,
        setLang,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

MyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyProvider;
