import React, { Fragment, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "./GlobalSearchFilter";
import { Link } from "react-router-dom";

interface filter {
  preGlobalFilteredRows: any;
  globalFilter?: any;
  setGlobalFilter: any;
  isJobListGlobalFilter: any;
}

interface TableContainerProps {
  columns?: any;
  data: any;
  isGlobalFilter?: any;
  isAddOptions?: any;
  isAddUserList?: any;
  isAddInvoiceList?: any;
  handleOrderClicks?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize: any;
  className?: any;
  handleInvoiceClick?: any;
  customPageSizeOptions?: any;
  isJobListGlobalFilter?: any;
  isGlobalSearch?: any;
  isProductsFilter?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isLeadsFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  isPagination?: any;
  filterOptionData?: any;
  filterOption?: any;
  isShowingPageLength?: boolean;
  paginationDiv?: string;
  pagination?: string;
  setCurrentCat?: any;
  currentCategory?: string;
  tableFor?: string;
}

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}: filter) {
  const count: any = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      {/* <Col xxl={3} lg={6}>
        <input type="search" className="form-control" id="search-bar-0" value={value || ""} placeholder={`${count} records...`} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} />
      </Col> */}
      {/* {isJobListGlobalFilter && <JobListGlobalFilter setGlobalFilter={setGlobalFilter} />} */}
    </React.Fragment>
  );
}

const TableContainer: any = ({
  columns,
  data,
  isAddOptions,
  handleOrderClicks,
  customPageSize,
  tableClass,
  setCurrentCat,
  currentCategory,
  theadClass,
  filterOption,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  filterOptionData,
  tableFor,
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const pageNumbers: any = [];
  for (let i = 1; i <= Math.ceil(data?.length / customPageSize); i++) {
    pageNumbers.push(i);
  }

  const onChangeInSelect = (event: any) => {
    setCurrentCat(String(event.target.value));
  };

  return (
    <Fragment>
      <Row className="mb-2">
        {filterOption && (
          <Col>
            <select
              className="form-select"
              value={currentCategory}
              onChange={onChangeInSelect}
            >
              <option value="">All</option>
              {filterOptionData?.length &&
                filterOptionData?.map((cat, i) => (
                  <option key={i} value={cat._id}>
                    {cat.title}
                  </option>
                ))}
            </select>
          </Col>
        )}

        {isAddOptions && (
          <Col sm="7" xxl="8">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {tableFor}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    className={`${column.isSortable ? "sorting_asc" : ""}`}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* {generateSortingIndicator(column)} */}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      {isPagination && (
        <div className="d-flex flex-sm-row flex-column justify-content-center align-items-center my-3">
          {isShowingPageLength && (
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            </div>
          )}
          <div className={paginationDiv}>
            <ul className={pagination}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageOptions.map((item: any, key: number) => (
                <React.Fragment key={key}>
                  <li
                    className={
                      pageIndex === item ? "page-item active" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => gotoPage(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default TableContainer;
