import React, { useEffect, useState } from "react";
import withRouter from "../../Components/Common/withRouter";
import { Space, Table } from "antd";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import axios from "axios";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Account Id",
    dataIndex: "accountId",
    key: "accountId",
  },
  {
    title: "Submitted On",
    key: "createdOn",
    render: (_, record) => (
      <>
        <Moment date={record.createdOn} format="DD/M/y hh:mm a" />
      </>
    ),
  },
  {
    title: "Rejected By",
    dataIndex: "requestHandledBy",
    key: "requestHandledBy",
  },
  {
    title: "Rejection Reason",
    dataIndex: "rejectionReason",
    key: "rejectionReason",
  },
  {
    title: "Rejection Date",
    key: "rejectionDate",
    // dataIndex: 'tags',
    render: (_, record) => (
      <>
        <Moment date={record.rejectionDate} format="DD/M/y hh:mm a" />
      </>
    ),
  },
];

const Reject = () => {
  // Meta title
  document.title = "Rejected Requests";

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const fetchBonusRequests = async (
    page,
    pageSize,
    from = "",
    to = "",
    search = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/rejected/bonus/requests`,
        {
          params: {
            page,
            limit: pageSize,
            from,
            to,
            search,
          },
        }
      );
      if (res.success) {
        setData(res.data);
        setPagination({
          current: res.currentPage,
          pageSize,
          total: res.totalPages * pageSize,
        });
      }
    } catch (error) {
      console.error("Failed to fetch bonus requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  }, [dateRange, searchTerm]);

  const handleTableChange = (pagination) => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  };

  const handleDateChange = (selectedDates, dateStr) => {
    setDateRange({
      from: selectedDates[0]
        ? moment(selectedDates[0]).format("YYYY-MM-DD")
        : "",
      to: selectedDates[1] ? moment(selectedDates[1]).format("YYYY-MM-DD") : "",
      fullDate: dateStr,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Bonus"
            breadcrumbItem="Rejected Profit Requests"
          />
          <Row className="mb-3 justify-content-between">
            <Col lg="5">
              <div className="align-self-center">
                <label> Search By Date Range</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Please select a range to fetch rejected requests"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                  }}
                  onClose={handleDateChange}
                  id="default-picker"
                />
              </div>
            </Col>
            <Col lg="5">
              <div>
                <label htmlFor="example-search-input" className="mt-2">
                  Search
                </label>
                <div>
                  <input
                    style={{ width: "100%" }}
                    className="form-control"
                    type="search"
                    placeholder="Search By Name, Email Or account"
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) =>
                handleTableChange({ current: page, pageSize }),
            }}
            loading={loading}
            rowKey="_id"
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Reject);
