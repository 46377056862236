import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from "react";

import withRouter from "../../Components/Common/withRouter";
import { Space, Table, Tag } from "antd";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  ModalFooter,
} from "reactstrap";

import { ImCross } from "react-icons/im";
import { ImCheckmark } from "react-icons/im";
import { MdOutlineEdit } from "react-icons/md";
import Breadcrumbs from "../../Components/Common/Breadcrumb";

// import {
//   getUsers as onGetUsers,
//   deleteUsers as onDeleteUsers,
//   addNewUser as onAddNewUser,
//   updateUser as onUpdateUser
// }
//   from "../../slices/contacts/thunk"
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Moment from "react-moment";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const Requests = () => {
  //meta title
  document.title = "Bonus Requests";
  const [data, setData] = useState([]);
  const [approvedModal, setApprovedModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [adjustModal, setAdjustModal] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState("");
  const [adjustAmount, setAdjustAmount] = useState("");

  const [rejection, setRejection] = useState("");
  const [currentRequestId, setCurrentRequestId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });
  const [searchTerm, setSearchTerm] = useState("");

  const approvedToggle = (id) => {
    setApprovedModal(!approvedModal);
    setCurrentRequestId(id);
  };

  const rejectToggle = (id) => {
    setRejectModal(!rejectModal);
    setCurrentRequestId(id);
  };

  const adjustToggle = (id) => {
    setAdjustModal(!adjustModal);
    setCurrentRequestId(id);
  };

  const opt = [
    {
      label: "Rejection",
      value: "Rejection",
    },
    {
      label: "Adjustment",
      value: "Adjustment",
    },
  ];

  const UserData = JSON.parse(localStorage.getItem("authUser"));

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Account Id",
      dataIndex: "accountId",
      key: "address",
    },
    {
      title: "Amount",
      // DataIndex: "requestedAmount",
      key: "Amount",
      render:  (_, record) => (
        <>
          <p>{record.requestedAmount}</p>
        </>
      ),
      //  (text) => <a>{text}</a>,
    },
    {
      title: "Submitted On",
      key: "createdOn",
      // dataIndex: 'tags',
      render: (_, record) => (
        <>
          <Moment date={record.createdOn} format="DD/M/y hh:mm a" />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => approvedToggle(record._id)}
            className="bonus-action2 btn btn-sm btn-soft-success text-success"
          >
            <ImCheckmark />
          </a>
          <a
            className="bonus-action2 btn btn-sm btn-soft-danger text-danger"
            onClick={() => rejectToggle(record._id)}
          >
            <ImCross />
          </a>
          <a
            className="bonus-actions btn btn-sm btn-soft-primary text-primary"
            onClick={() => adjustToggle(record._id)}
          >
            <MdOutlineEdit />
          </a>
        </Space>
      ),
    },
  ];

  const fetchBonusRequests = async (
    page,
    pageSize,
    from = "",
    to = "",
    search = ""
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/bonus/requests`,
        {
          params: {
            page,
            limit: pageSize,
            from,
            to,
            search,
          },
        }
      );
      if (res.success) {
        console.log(res.data, "acha");
        setData(res.data);
        setPagination({
          current: res.currentPage,
          pageSize,
          total: res.totalPages * pageSize,
        });
      }
    } catch (error) {
      console.error("Failed to fetch bonus requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  }, [dateRange, searchTerm]);

  const handleTableChange = (pagination) => {
    fetchBonusRequests(
      pagination.current,
      pagination.pageSize,
      dateRange.from,
      dateRange.to,
      searchTerm
    );
  };

  const handleDateChange = (selectedDates, dateStr) => {
    setDateRange({
      from: selectedDates[0]
        ? moment(selectedDates[0]).format("YYYY-MM-DD")
        : "",
      to: selectedDates[1] ? moment(selectedDates[1]).format("YYYY-MM-DD") : "",
      fullDate: dateStr,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleApprovedSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/approve/bonus/request`,
        {
          id: currentRequestId,
          requestHandledBy: `${UserData.user.name.firstName} ${UserData.user.name.lastName}`,
          amountApproved: approvedAmount,
          status: {
            pending: false,
            accept: true,
          },
        }
      );
      if (response?.success) {
        console.log(response.data, "approved");
        const updatedData = data.filter(
          (item) => item._id !== currentRequestId
        );
        setData(updatedData);
        approvedToggle(null);
        setApprovedAmount("");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRejectSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/reject/bonus/request`,
        {
          id: currentRequestId,
          requestHandledBy: `${UserData.user.name.firstName} ${UserData.user.name.lastName}`,
          rejectionReason: rejection,
          status: {
            pending: false,
            reject: true,
          },
        }
      );
      if (response?.success) {
        console.log(response.data, "reject");
        const recordData = data;
        const updatedData = recordData.filter(
          (item) => item._id !== currentRequestId
        );
        setData(updatedData);
        rejectToggle(null);
        setRejection("");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdjustmentSubmit = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/update/adjustment/bonus/request`,
        {
          id: currentRequestId,
          requestHandledBy: `${UserData.user.name.firstName} ${UserData.user.name.lastName}`,
          adjustAmount: adjustAmount,
          rejectionReason: rejection,
          status: {
            pending: false,
            reject: true,
          },
        }
      );
      if (response?.success) {
        console.log(response.data, "adjustAmount");
        const recordData = data;
        const updatedData = recordData.filter(
          (item) => item._id !== currentRequestId
        );
        setData(updatedData);
        adjustToggle(null);
        setAdjustAmount("");
        setRejection("");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(data, "<<==== data");
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bonus" breadcrumbItem="Profit Bonus" />
          <Row className="mb-3 justify-content-between">
            <Col lg="5">
              <div className="align-self-center">
                <label> Search By Date Range</label>
                <Flatpickr
                  className="form-control"
                  placeholder="Please select a range to fetch bonus requests"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                  }}
                  onClose={handleDateChange}
                  id="default-picker"
                />
              </div>
            </Col>
            <Col lg="5">
              <div>
                <label htmlFor="example-search-input" className="mt-2">
                  Search
                </label>
                <div>
                  <input
                    style={{ width: "100%" }}
                    className="form-control"
                    type="search"
                    placeholder="Search By Name, Email Or account "
                    onChange={handleSearchChange}
                    value={searchTerm}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) =>
                handleTableChange({ current: page, pageSize }),
            }}
            loading={loading}
          />
          <ToastContainer />
          <Modal isOpen={approvedModal} toggle={() => approvedToggle(null)}>
            <ModalHeader toggle={() => approvedToggle(null)}>
              Approved Request
            </ModalHeader>
            <ModalBody>
              <FormGroup className="mb-3">
                <Label for="approvedAmount">Approved Amount</Label>
                <Input
                  type="number"
                  id="approvedAmount"
                  value={approvedAmount}
                  onChange={(e) => setApprovedAmount(e.target.value)}
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleApprovedSubmit}>
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal isOpen={rejectModal} toggle={() => rejectToggle(null)}>
            <ModalHeader toggle={() => rejectToggle(null)}>
              Rejection
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="rejectionReason">Notes</Label>
                <Input
                  type="textarea"
                  id="rejectionReason"
                  value={rejection}
                  onChange={(e) => setRejection(e.target.value)}
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleRejectSubmit}>
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal isOpen={adjustModal} toggle={() => adjustToggle(null)}>
            <ModalHeader toggle={() => adjustToggle(null)}>
              Adjustment
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="approvedAmount">Correct Amount</Label>
                <Input
                  type="number"
                  id="adjustAmount"
                  value={adjustAmount}
                  onChange={(e) => setAdjustAmount(e.target.value)}
                  required
                  className="mb-3"
                />
                <Label for="rejectionReason">Notes</Label>
                <Input
                  type="textarea"
                  id="rejectionReason"
                  value={rejection}
                  onChange={(e) => setRejection(e.target.value)}
                  required
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleAdjustmentSubmit}>
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Requests);
